<template>
  <div id="mian" class="container">
    <Tabs v-model="curTab">
      <TabPane label="基本信息" name="tab1"></TabPane>
    </Tabs>
    <!-- 基本信息 -->
    <div v-if="curTab === 'tab1'">
      <div class="desc">
        <h5 class="title float_left">基本信息</h5>
        <button
          v-auth="'BASIC:FILEMANA:ACCOUNTLIST/DETAIL/EDIT'"
          v-if="type === 'detail' && activateStatus !== 'FROZEN'"
          class="ivu-btn edit-btn"
          @click="handleClickEdit"
        >
          编辑
        </button>
      </div>
      <template v-if="type === 'detail'">
        <Info :detail="detail"></Info>
      </template>
      <template v-if="isEdit || isAdd">
        <div class="tab1-input">
          <Form
            ref="formValidate"
            class="form"
            :model="formData"
            :label-width="80"
          >
            <FormItem class="form-item" prop="allocMchName">
              <span class="tab-label"><i>*</i>分账方全称 </span>
              <Input
                v-if="
                  !['NOT_PASSED', 'NOT_UPLOAD', 'PASSED'].includes(
                    approveImg
                  ) && formData.mchType != 'PERSONAL'
                "
                v-model="formData.allocMchName"
                class="inputs"
                size="large"
                placeholder="输入分账方全称"
                :disabled="isEdit"
              >
              </Input>
              <Input
                v-else
                v-model="formData.allocMchName"
                class="inputs"
                size="large"
                placeholder="输入分账方全称"
              >
              </Input>
            </FormItem>
            <FormItem class="form-item" prop="allocMchType">
              <span class="tab-label"><i>*</i>分账方属性 </span>
              <Select
                v-model="formData.allocMchType"
                size="large"
                placeholder="选择分账方属性"
                :disabled="isEdit"
              >
                <Option
                  v-for="item in filteredOptions"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.title }}</Option
                >
              </Select>
            </FormItem>
            <!-- <FormItem v-if="!isPlatalloc" class="form-item" prop="bussinessType">
              <span class="tab-label"><i>*</i>所属商户 </span>
              <Select v-model="formData.mchId" size="large" placeholder="选择所属商户">
                <Option v-for="item in mchDropOptions" :value="item.mchId" :key="item.mchName">{{ item.mchName }}</Option>
              </Select>
            </FormItem> -->
            <FormItem class="form-item" prop="mchShortName">
              <span class="tab-label">分账方简称 </span>
              <Input
                v-model="formData.mchShortName"
                class="inputs"
                size="large"
                placeholder="输入分账方简称"
                :disabled="isEdit"
              ></Input>
            </FormItem>
            <FormItem
              v-if="!isPlatalloc"
              class="form-item"
              prop="bussinessType"
            >
              <span class="tab-label"><i>*</i>分账方角色 </span>
              <Select
                v-model="formData.allocMchRole"
                size="large"
                placeholder="选择分账方角色"
              >
                <Option
                  v-for="item in allocMchRoleOptions"
                  :value="item.value"
                  :key="item.title"
                  >{{ item.title }}</Option
                >
              </Select>
            </FormItem>
            <FormItem class="form-item" prop="mchType">
              <span class="tab-label"
                ><i v-if="!isPersonal">*</i>分账方类型
              </span>
              <Select
                v-model="formData.mchType"
                size="large"
                placeholder="选择分账方类型"
                :disabled="isEdit"
              >
                <Option
                  v-for="item in getMchTypeOptions"
                  :value="item.value"
                  :key="item.title"
                  >{{ item.title }}</Option
                >
              </Select>
            </FormItem>
            <FormItem class="form-item" prop="contactTel">
              <span class="tab-label"><i>*</i>联系人 </span>
              <Input
                v-model="formData.contactName"
                class="inputs"
                size="large"
                placeholder="输入联系人"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="contactTel">
              <span class="tab-label"><i>*</i>联系人手机 </span>
              <Input
                v-model="formData.contactTel"
                maxlength="11"
                class="inputs"
                size="large"
                placeholder="输入联系人手机"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="contactTel">
              <span class="tab-label"><i>*</i>法人姓名 </span>
              <Input
                v-if="
                  !['NOT_PASSED', 'NOT_UPLOAD', 'PASSED'].includes(approveImg)
                "
                v-model="formData.legalPersonName"
                class="inputs"
                size="large"
                placeholder="输入法人姓名"
                :disabled="isEdit"
              >
              </Input>
              <Input
                v-else
                v-model="formData.legalPersonName"
                class="inputs"
                size="large"
                placeholder="输入法人姓名"
              >
              </Input>
            </FormItem>
            <FormItem v-if="!isPersonal" class="form-item" prop="legalPersonNo">
              <span class="tab-label"><i>*</i>法人身份证号码 </span>
              <Input
                v-if="
                  !['NOT_PASSED', 'NOT_UPLOAD', 'PASSED'].includes(approveImg)
                "
                v-model="formData.legalPersonNo"
                class="inputs"
                size="large"
                placeholder="输入法人身份证号码"
                :disabled="isEdit"
              >
              </Input>
              <Input
                v-else
                v-model="formData.legalPersonNo"
                class="inputs"
                size="large"
                placeholder="输入法人身份证号码"
              >
              </Input>
            </FormItem>
            <FormItem v-else class="form-item" prop="legalPersonNo">
              <span class="tab-label"><i>*</i>法人身份证号码 </span>
              <Input
                v-if="!['NOT_PASSED', 'NOT_UPLOAD'].includes(approveImg)"
                v-model="formData.legalPersonNo"
                class="inputs"
                size="large"
                placeholder="输入法人身份证号码"
                :disabled="isEdit"
              >
              </Input>
              <Input
                v-else
                v-model="formData.legalPersonNo"
                class="inputs"
                size="large"
                placeholder="输入法人身份证号码"
              >
              </Input>
            </FormItem>
            <FormItem class="form-item" prop="legalPersonPhone">
              <span class="tab-label"><i>*</i>法人手机号码 </span>
              <Input
                v-model="formData.legalPersonPhone"
                maxlength="11"
                class="inputs"
                size="large"
                placeholder="输入法人手机号码"
                :disabled="isEdit"
              ></Input>
            </FormItem>
            <FormItem
              class="form-item form-address"
              prop="legalPersonCemStartDate"
            >
              <span class="tab-label">法人证件有效期 </span>
              <div class="form-date-picker">
                <FormItem style="flex: 1" prop="legalPersonCemStartDate">
                  <DatePicker
                    v-model="formData.legalPersonCemStartDate"
                    class="date-picker"
                    type="date"
                    size="large"
                    placeholder="开始日期"
                  ></DatePicker>
                </FormItem>
                <span class="split">至</span>
                <FormItem style="flex: 1" prop="legalPersonCemEndDate">
                  <DatePicker
                    v-model="formData.legalPersonCemEndDate"
                    :options="options"
                    class="date-picker"
                    type="date"
                    size="large"
                    placeholder="结束日期"
                  ></DatePicker>
                </FormItem>
              </div>
            </FormItem>
            <FormItem class="form-item" prop="licenceNo">
              <span class="tab-label"
                ><i v-if="!isPersonal">*</i>统一社会信用代码
              </span>
              <Input
                v-if="
                  !['NOT_PASSED', 'NOT_UPLOAD'].includes(approveImg) &&
                  !isPersonal
                "
                v-model="formData.licenceNo"
                class="inputs"
                size="large"
                placeholder="输入统一社会信用代码"
                :disabled="isEdit"
              >
              </Input>
              <Input
                v-else
                v-model="formData.licenceNo"
                class="inputs"
                size="large"
                placeholder="输入统一社会信用代码"
              >
              </Input>
            </FormItem>
            <FormItem class="form-item form-address" prop="licenceStartDate">
              <span class="tab-label">营业执照有效期 </span>
              <div class="form-date-picker">
                <FormItem style="flex: 1">
                  <DatePicker
                    v-model="formData.licenceStartDate"
                    class="date-picker"
                    type="date"
                    size="large"
                    placeholder="开始日期"
                  ></DatePicker>
                </FormItem>
                <span class="split">至</span>
                <FormItem style="flex: 1" prop="licenceEndDate">
                  <DatePicker
                    v-model="formData.licenceEndDate"
                    :options="options"
                    class="date-picker"
                    type="date"
                    size="large"
                    placeholder="结束日期"
                  ></DatePicker>
                </FormItem>
              </div>
            </FormItem>
            <FormItem class="form-item" prop="businessScope">
              <span class="tab-label">经营范围 </span>
              <Input
                v-model="formData.businessScope"
                class="inputs"
                size="large"
                placeholder="输入经营范围"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="businessAddress">
              <span class="tab-label"
                ><i v-if="!isPersonal">*</i>营业地址
              </span>
              <Input
                v-model="formData.businessAddress"
                class="inputs"
                size="large"
                placeholder="输入经营地址"
              ></Input>
            </FormItem>
          </Form>
        </div>
      </template>
      <div>
        <div class="desc">
          <h5 class="title float_left">结算信息</h5>
        </div>
        <Table :columns="settleTableColumns" :data="settleTableData">
          <template slot="action" slot-scope="{ row }">
            <a v-if="activateStatus !== 'FROZEN'" @click="editSettle(row)"
              >编辑</a
            >
          </template>
        </Table>
        <a
          v-if="isAdd && settleTableData.length === 0"
          class="add-btn"
          @click="addSettle"
          >+ 新增结算信息</a
        >
      </div>
      <div v-if="!isAdd && !isEdit">
        <div class="desc">
          <h5 class="title float_left">分账方状态</h5>
        </div>
        <Table :columns="accountTableColumns" :data="accountTableData">
          <template slot="action" slot-scope="{ row }">
            <a
              v-auth="'BASIC:FILEMANA:ACCOUNTLIST/DETAIL/SYNC'"
              class="btn"
              @click="showModal(1, row)"
              >同步信息</a
            >
            <a
              v-auth="'BASIC:FILEMANA:ACCOUNTLIST/DETAIL/SIGN'"
              class="btn"
              @click="showModal(2, row)"
              >商户签约</a
            >
            <a
              v-auth="'BASIC:FILEMANA:ACCOUNTLIST/DETAIL/UPLOAD'"
              @click="showModal(3, row)"
              >上传图片</a
            >
          </template>
        </Table>
      </div>
      <div class="tab1-footer">
        <div v-if="isEdit || isAdd">
          <Button @click="handleClickCancel">返回</Button>
          <Button type="primary" @click="onSubmit" :disabled="disabled"
            >保存</Button
          >
        </div>
      </div>
    </div>
    <Modal v-model="overAccountShow" title="结算账户" width="490px">
      <ul class="overAccount_ul clearfix">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>账户类型</div>
          <Select
            v-model="settleFormData.accountType"
            placeholder="选择账户类型"
            tabindex="1"
          >
            <Option
              v-for="(v, i) in accountTypeOptions"
              :key="i"
              :label="v.title"
              :value="v.value"
            >
            </Option>
          </Select>
          <div class="overAccount_ul_title"><i>*</i>账户</div>
          <Input
            v-model="settleFormData.accountNo"
            maxlength="128"
            placeholder="输入账户"
            tabindex="3"
          ></Input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>账户名</div>
          <Input
            v-model="settleFormData.accountName"
            maxlength="128"
            placeholder="输入账户名"
            tabindex="2"
          ></Input>
          <div class="overAccount_ul_title">
            <i v-show="settleFormData.accountType == 'COMPANY'">*</i>联行号
          </div>
          <Input
            v-model="settleFormData.interBranchesNo"
            maxlength="128"
            placeholder="输入联行号"
            tabindex="4"
          ></Input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <Button class="cancel-btn" size="small" @click="overAccountShow = false"
          >取 消</Button
        >
        <Button class="confirm-btn" size="small" type="primary" @click="sureAdd"
          >确 定</Button
        >
      </span>
    </Modal>
    <!-- 同步信息弹框 -->
    <Modal v-model="isShow" :title="modalTitle" width="490px">
      <template v-if="modalType === 1">
        <div class="sync-modal">
          <div class="sync-modal-title">选择类型</div>
          <Select v-model="statusSync" placeholder="选择类型">
            <Option label="商户状态" value="MCH"></Option>
            <Option label="签约状态" value="SIGN"></Option>
            <Option label="图片审核状态" value="APPROVE"></Option>
          </Select>
        </div>
        <span slot="footer" class="dialog-footer">
          <Button class="cancel-btn" size="small" @click="isShow = false"
            >取 消</Button
          >
          <Button
            class="confirm-btn"
            type="primary"
            size="small"
            @click="handleClickSync"
            >同 步</Button
          >
        </span>
      </template>
      <template v-if="modalType === 2">
        <div class="sync-modal">
          <span class="sync-modal-title"
            >签约分账方：{{ accountTableData[0].allocMchId }}</span
          >
          <a
            class="lianjie"
            @click="viewProtocol"
            style="display: inline-block; margin-left: 10px"
            >(查看协议内容)</a
          >
          <div class="copy-btn" @click="copyLink">复制签约链接</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <input
            type="text"
            ref="copyLinkInput"
            id="copyLinkInput"
            style="width: 10px; opacity: 0"
          />
          <Button class="cancel-btn" size="small" @click="isShow = false"
            >取 消</Button
          >
          <Button
            class="confirm-btn"
            size="small"
            type="primary"
            @click="handleClickSign"
            >签 约</Button
          >
        </span>
      </template>
      <template v-if="modalType === 3">
        <div class="overAccount_ul">
          <div class="upload_ph" style="padding: 0px 20px">
            <!-- 个人 -->
            <div class="uploader1" v-show="isPersonal">
              <h4>营业执照</h4>
              <Upload
                class="avatar-uploader"
                :before-upload="beforeUpload"
                type="drag"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="licencePictureSuccess"
              >
                <img
                  v-if="formData.licencePicture"
                  :src="formData.licencePicture"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span
                  v-if="formData.licencePicture"
                  class="avatar-uploader-span"
                  @click.stop="formData.licencePicture = ''"
                  ><i class="el-icon-close"></i
                ></span>
              </Upload>
            </div>
            <!-- 企业/个体户 -->
            <div class="uploader1" v-show="!isPersonal">
              <h4><i v-show="!isPersonal">*</i>营业执照</h4>
              <img
                v-if="
                  !['NOT_PASSED', 'NOT_UPLOAD', 'PASSED'].includes(approveImg)
                "
                :src="formData.licencePicture"
                class="approveImg"
              />
              <div v-else>
                <Upload
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  type="drag"
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="licencePictureSuccess"
                >
                  <img
                    v-if="formData.licencePicture"
                    :src="formData.licencePicture"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span
                    v-if="formData.licencePicture"
                    class="avatar-uploader-span"
                    @click.stop="formData.licencePicture = ''"
                    ><i class="el-icon-close"></i
                  ></span>
                </Upload>
              </div>
            </div>
            <div class="uploader1">
              <h4><i>*</i>法人身份证国徽照片</h4>
              <Upload
                class="avatar-uploader"
                :before-upload="beforeUpload"
                type="drag"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="idcardNormalPictureSuccess"
              >
                <img
                  v-if="formData.idcardNormalPicture"
                  :src="formData.idcardNormalPicture"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span
                  v-if="formData.idcardNormalPicture"
                  class="avatar-uploader-span"
                  @click.stop="formData.idcardNormalPicture = ''"
                  ><i class="el-icon-close"></i
                ></span>
              </Upload>
            </div>
            <div class="uploader1">
              <h4><i>*</i>法人身份证人像照片</h4>
              <img
                v-if="
                  !['NOT_PASSED', 'NOT_UPLOAD', 'PASSED'].includes(approveImg)
                "
                :src="formData.idcardReversePicture"
                class="approveImg"
              />
              <div v-else>
                <Upload
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  type="drag"
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="idcardReversePictureSuccess"
                >
                  <img
                    v-if="formData.idcardReversePicture"
                    :src="formData.idcardReversePicture"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span
                    v-if="formData.idcardReversePicture"
                    class="avatar-uploader-span"
                    @click.stop="formData.idcardReversePicture = ''"
                    ><i class="el-icon-close"></i
                  ></span>
                </Upload>
              </div>
            </div>
            <div class="uploader1">
              <h4><i v-show="isEnterprise">*</i>开户许可证</h4>
              <Upload
                class="avatar-uploader"
                :before-upload="beforeUpload"
                type="drag"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="accountOpeningLicenseSuccess"
              >
                <img
                  v-if="formData.accountOpeningLicense"
                  :src="formData.accountOpeningLicense"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span
                  v-if="formData.accountOpeningLicense"
                  class="avatar-uploader-span"
                  @click.stop="formData.accountOpeningLicense = ''"
                  ><i class="el-icon-close"></i
                ></span>
              </Upload>
            </div>
            <div class="uploader1">
              <h4>其他类型图片1</h4>
              <Upload
                class="avatar-uploader"
                :before-upload="beforeUpload"
                type="drag"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="otherPicture1Success"
              >
                <img
                  v-if="formData.otherPicture1"
                  :src="formData.otherPicture1"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span
                  v-if="formData.otherPicture1"
                  class="avatar-uploader-span"
                  @click.stop="formData.otherPicture1 = ''"
                  ><i class="el-icon-close"></i
                ></span>
              </Upload>
            </div>
            <div class="uploader1">
              <h4>其他类型图片2</h4>
              <Upload
                class="avatar-uploader"
                :before-upload="beforeUpload"
                type="drag"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="otherPicture2Success"
              >
                <img
                  v-if="formData.otherPicture2"
                  :src="formData.otherPicture2"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span
                  v-if="formData.otherPicture2"
                  class="avatar-uploader-span"
                  @click.stop="formData.otherPicture2 = ''"
                  ><i class="el-icon-close"></i
                ></span>
              </Upload>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <Button class="cancel-btn" size="small" @click="isShow = false"
            >取 消</Button
          >
          <Button
            class="confirm-btn"
            type="primary"
            size="small"
            :disabled="uploadImgsFlag"
            @click="uploadImgs"
            >确 认</Button
          >
        </span>
      </template>
    </Modal>
    <div class="cover-view" v-if="signHtml !== ''" @click="signHtml = ''">
      <div v-html="signHtml" class="webview"></div>
    </div>
  </div>
</template>

<script>
import * as imageConversion from "image-conversion";
import Info from "./components/info";
import { mapState, mapMutations } from "vuex";
import {
  getAllocmerchantsDetail,
  setAllocmerchants,
  setSettlements,
  getThirdaccounts,
  setThirdaccountsSign,
  setThirdaccountsSignC,
  setThirdaccountsSignUrl,
  setImage,
  addAllocmerchants,
} from "@/api/basic/fileManage.js";
import { URL_API } from "@/utils/apiUrl.js";
import { merchantsDrop } from "@/api/common.js";
import { formatDate } from "@/utils/common.js";

export default {
  components: { Info },
  data() {
    return {
      isUniformTreatment: Number(
        JSON.parse(sessionStorage.getItem("userInfo")).isUniformTreatment
      ), //资金统一处理状态 0关 1开
      activateStatus: "",
      curTab: "tab1",
      type: "detail",
      buttonFlag: false,
      options: {
        shortcuts: [
          {
            text: "长期",
            value() {
              return new Date("2999-01-01");
            },
          },
        ],
      },
      formData: {
        mchModel: 1, // 0单商户入网 1服务商入网
        mchName: "", //商户名称
        mchType: "", //商户类型，
        bussinessType: "", //行业类别
        licenceNo: "", //营业执照编号
        companyAddress: "", //营业执照注册地址
        licenceBegin: "", //营业执照有效期
        licenceEnd: "", //营业执照有效期
        legalPersonName: "", //法人姓名
        legalPersonPhone: "", //法人手机号码
        idcardNo: "", //法人身份证号码
        legalPersonCemStartDate: "", //法人证件有效期
        legalPersonCemEndDate: "", //法人证件有效期
        contactTel: "", //联系号码
        contactEmail: "", //联系邮箱
        accountOpeningLicenceNo: "", //开户许可证编号
        contactName: "", //取值-法人姓名

        registryProvince: "", //省
        registryCity: "", //市
        registryDistrict: "", //区
        registryAddress: "", //详细地址

        licencePicture: "", //营业执照照片URL
        idcardNormalPicture: "", //	身份证国徽照片（法人或联系人）
        idcardReversePicture: "", //	身份证人像照片（法人或联系人
        accountOpeningLicense: "", //开户许可证图片URL
        otherPicture1: "",
        otherPicture2: "",
      },
      detail: {},
      // 筛选项
      accountTypeOptions: [
        { title: "企业", value: "COMPANY" },
        { title: "个人", value: "PERSONAL" },
      ],
      mchTypeOptions: [
        { title: "企业", value: "ENTERPRISE" },
        { title: "个体工商户", value: "INDIVIDUAL_BUSINESS" },
        { title: "个人", value: "PERSONAL" },
      ],
      allocMchOptions: [
        { title: "平台分账方", value: "PLATALLOC" },
        { title: "服务商分账方", value: "AGENTALLOC" },
      ],
      allocMchRoleOptions: [
        { title: "供应商", value: "SUPPLIER" },
        { title: "分销商", value: "DISTRIBUTOR" },
        { title: "MCN机构", value: "MCN" },
        { title: "销售员", value: "SELLER" },
        { title: "主播", value: "ANCHOR" },
        { title: "其他", value: "OTHER" },
      ],
      mchDropOptions: [],

      // 结算信息
      columns: [],
      settleTableColumns: [
        {
          title: "账户名",
          key: "accountName",
          align: "center",
        },
        {
          title: "账号",
          key: "accountNo",
          align: "center",
        },
        {
          title: "联行号",
          key: "interBranchesNo",
          align: "center",
        },
        {
          title: "账户类型",
          key: "accountType",
          align: "center",
          render(h, { row }) {
            const map = {
              COMPANY: "企业",
              PERSONAL: "个人",
            };
            return h("p", map[row.accountType]);
          },
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          slot: "action",
        },
      ],
      settleTableData: [],
      // 分账方信息
      accountTableColumns: [
        {
          title: "三方分账方编号",
          key: "allocMchNo",
          align: "center",
        },
        {
          title: "分账方登录名",
          key: "loginName",
          align: "center",
        },
        {
          title: "商户状态",
          key: "mchStatus",
          align: "center",
          render(h, { row }) {
            const map = {
              NOTACTIVATE: "未激活",
              ACTIVATE: "已激活",
              FROZEN: "已冻结",
              CLOSED: "已关闭",
            };
            return h("p", map[row.mchStatus]);
          },
        },
        {
          title: "认证状态",
          key: "authStatus",
          align: "center",
          render(h, { row }) {
            const map = {
              UNAUTHORIZED: "未认证",
              AUTHENTICATING: "认证中",
              AUTH_FAILED: "认证失败",
              AUTH_SUCCESSED: "认证成功",
            };
            return h("p", map[row.authStatus]);
          },
        },
        {
          title: "签约状态",
          key: "signStatus",
          align: "center",
          render(h, { row }) {
            const map = {
              NOT_SIGNED: "未签约",
              SIGNED: "已签约",
            };
            return h("p", map[row.signStatus]);
          },
        },
        {
          title: "图片审核状态",
          key: "approveStatus",
          align: "center",
          render(h, { row }) {
            const map = {
              NOT_UPLOAD: "未上传",
              NOT_APPROVED: "未审批",
              PASSED: "审批通过",
              NOT_PASSED: "审批未通过",
            };
            return h("p", map[row.approveStatus]);
          },
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          slot: "action",
        },
      ],
      accountTableData: [],

      // 弹窗
      overAccountShow: false,
      settleFormData: {
        accountType: "",
        accountNo: "",
        accountName: "",
        interBranchesNo: "",
      },

      isShow: false,
      modalType: 1,
      statusSync: "MCH", // 当前同步的状态
      signHtml: "",
      copyLinkTitle: "",
      uploadUrl: URL_API.upload,
      uploadImgsFlag: false,
      approveImg: "",
      disabled: false,
    };
  },
  computed: {
    ...mapState({ accountData: "account_data" }),
    isPlatalloc() {
      return this.formData.allocMchType === "PLATALLOC";
    },
    isAgentalloc() {
      return this.formData.allocMchType === "AGENTALLOC";
    },
    isPersonal() {
      return this.formData.mchType === "PERSONAL";
    },
    isEnterprise() {
      return this.formData.mchType === "ENTERPRISE";
    },
    isAdd() {
      return this.type === "add";
    },
    isEdit() {
      return this.type === "edit";
    },
    modalTitle() {
      let map = {
        1: "同步信息",
        2: "商户签约",
        3: this.approveImg === "NOT_UPLOAD" ? "上传图片" : "修改图片",
      };
      return map[this.modalType];
    },
    getMchTypeOptions() {
      if (
        this.formData.allocMchType === "PLATALLOC" ||
        !this.formData.allocMchType
      ) {
        return this.mchTypeOptions.filter((item) => item.value !== "PERSONAL");
      }
      return this.mchTypeOptions;
    },
    // 资金统一处理为否时，分账方属性仅为平台分账方
    filteredOptions() {
      return this.isUniformTreatment
        ? this.allocMchOptions
        : this.allocMchOptions.filter((item) => item.value === "PLATALLOC");
    },
  },

  created() {
    this.type = this.$route.query.type || "detail";

    if (this.accountData) {
      (this.formData = this.accountData.formData),
        (this.settleTableData = this.accountData.settleTableData);
    }

    if (this.type === "detail") {
      this.getDetail();
      this.columns = Object.assign([], this.settleTableColumns);
      this.updateColumns();
    }
    this.getMerchantsDrop();

    const { activateStatus } = JSON.parse(sessionStorage.getItem("userInfo"));
    this.activateStatus = activateStatus;
  },

  destroyed() {
    if (this.isAdd) {
      let data = {
        formData: this.formData,
        settleTableData: this.settleTableData,
      };
      this.setAccounData(data);
    }
  },

  methods: {
    ...mapMutations({ setAccounData: "set_account_data" }),
    // 获取详情
    getDetail() {
      getAllocmerchantsDetail(this.$route.query.id).then((res) => {
        if (res && res.resultStatus) {
          this.detail = res.resultData.mchDetail || {};
          this.formData = Object.assign({}, this.detail);
          this.settleTableData = res.resultData.settlements || [];
          if (this.settleTableData.length > 0) {
            this.settleFormData = res.resultData.settlements[0];
          }
          this.accountTableData = res.resultData.thirdAccounts || [];
          if (this.accountTableData.length > 0) {
            this.approveImg = res.resultData.thirdAccounts[0].approveStatus;
          }
        }
      });
    },
    getMerchantsDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDropOptions = res.resultData;
        }
      });
    },
    handleClickEdit() {
      this.type = "edit";
      this.formData = Object.assign({}, this.detail);
      this.formatData();
      this.getMerchantsDrop();
      this.updateColumns();
    },
    formatData() {
      if (this.formData.licenceEndDate === "forever") {
        this.formData.licenceEndDate = "2999-01-01";
      }
      if (this.formData.legalPersonCemEndDate === "forever") {
        this.formData.legalPersonCemEndDate = "2999-01-01";
      }
    },
    updateColumns() {
      if (this.isEdit) {
        this.settleTableColumns = this.columns;
      } else {
        this.settleTableColumns = this.settleTableColumns.filter(
          (item) => item.key !== "action"
        );
      }
    },
    handleClickCancel() {
      if (this.isAdd) {
        this.$router.go(-1);
      } else {
        this.type = "detail";
        this.updateColumns();
      }
    },
    // 表单规则
    validate() {
      let data = this.formData;
      if (!data.allocMchName) {
        this.$Message.error("分账方全称必填");
        return false;
      }
      if (!data.allocMchType) {
        this.$Message.error("分账方属性必选");
        return false;
      }
      if (data.allocMchType == "AGENTALLOC") {
        // if (!data.mchId) {
        //   this.$Message.error("所属商户必选")
        //   return false
        // }
        if (!data.allocMchRole) {
          this.$Message.error("分账方角色必选");
          return false;
        }
      }
      if (!data.mchType) {
        this.$Message.error("分账方类型必选");
        return false;
      }
      if (!data.contactName) {
        this.$Message.error("联系人必填");
        return false;
      }
      if (!data.contactTel) {
        this.$Message.error("联系人手机必填");
        return false;
      }
      if (
        !this.$route.query.id &&
        !/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(data.contactTel)
      ) {
        this.$Message.error("请正确填写手机号码");
        return false;
      }
      if (!data.legalPersonName) {
        this.$Message.error("法人姓名必填");
        return false;
      }
      if (!data.legalPersonNo) {
        this.$Message.error("法人身份证号码必填");
        return false;
      }
      if (!data.legalPersonPhone) {
        this.$Message.error("法人手机号码必填");
        return false;
      }
      if (
        !this.$route.query.id &&
        !/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(data.legalPersonPhone)
      ) {
        this.$Message.error("请正确填写手机号码");
        return false;
      }
      if (!data.licenceNo && data.mchType != "PERSONAL") {
        this.$Message.error("统一社会信用代码必填");
        return false;
      }
      if (!data.businessAddress && data.mchType != "PERSONAL") {
        this.$Message.error("营业地址必填");
        return false;
      }
      if (data.licenceStartDate || data.licenceEndDate) {
        if (!data.licenceStartDate) {
          this.$Message.error("请选择营业执照有效期开始时间");
          return false;
        }
        if (!data.licenceEndDate) {
          this.$Message.error("请选择营业执照有效期结束时间");
          return false;
        }
      }
      if (data.legalPersonCemStartDate || data.legalPersonCemEndDate) {
        if (!data.legalPersonCemStartDate) {
          this.$Message.error("请选择法人证件有效期开始时间");
          return false;
        }
        if (!data.legalPersonCemEndDate) {
          this.$Message.error("请选择法人证件有效期结束时间");
          return false;
        }
      }
      return true;
    },
    onSubmit() {
      if (!this.validate()) {
        return;
      }
      const formData = Object.assign({}, this.formData);
      let data = {
        mchDetail: formData,
        settlements: this.settleTableData,
      };
      delete data.mchDetail.createTime;
      delete data.mchDetail.updateTime;
      if (data.mchDetail.legalPersonCemStartDate) {
        data.mchDetail.legalPersonCemStartDate = formatDate(
          data.mchDetail.legalPersonCemStartDate,
          "yyyy-MM-dd"
        );
      }
      if (data.mchDetail.legalPersonCemEndDate) {
        data.mchDetail.legalPersonCemEndDate = formatDate(
          data.mchDetail.legalPersonCemEndDate,
          "yyyy-MM-dd"
        );
      }
      if (data.mchDetail.licenceStartDate) {
        data.mchDetail.licenceStartDate = formatDate(
          data.mchDetail.licenceStartDate,
          "yyyy-MM-dd"
        );
      }
      if (data.mchDetail.licenceEndDate) {
        data.mchDetail.licenceEndDate = formatDate(
          data.mchDetail.licenceEndDate,
          "yyyy-MM-dd"
        );
      }
      if (data.mchDetail.legalPersonCemEndDate == "2999-01-01") {
        data.mchDetail.legalPersonCemEndDate = "forever";
      }
      if (data.mchDetail.licenceEndDate == "2999-01-01") {
        data.mchDetail.licenceEndDate = "forever";
      }

      this.disabled = true;

      if (this.isEdit) {
        data.thirdAccounts = this.accountTableData;
        setAllocmerchants(this.$route.query.id, data).then((res) => {
          this.disabled = false;
          if (res && res.resultStatus) {
            this.$Message.success("保存成功");
            this.type = "detail";
            this.getDetail();
            this.updateColumns();
          }
        });
      }
      if (this.isAdd) {
        addAllocmerchants(data).then((res) => {
          this.disabled = false;
          if (res && res.resultStatus) {
            this.$Message.success("保存成功");
            this.setAccounData(null);
            this.$router.push({
              name: "accountList",
            });
          }
        });
      }
    },

    addSettle() {
      this.overAccountShow = true;
      this.settleFormData = {
        accountType: "",
        accountNo: "",
        accountName: "",
        interBranchesNo: "",
      };
    },
    editSettle(row) {
      this.overAccountShow = true;
      this.settleFormData = Object.assign({}, row);
    },
    sureAdd() {
      let data = Object.assign({}, this.settleFormData);
      if (!data.accountType) {
        this.$Message.error("账户类型必选");
        return false;
      }
      if (!data.accountNo) {
        this.$Message.error("结算账户必填");
        return false;
      }
      if (!data.accountName) {
        this.$Message.error("结算账户名称必填");
        return false;
      }
      if (data.accountType === "COMPANY") {
        if (!data.interBranchesNo) {
          this.$Message.error("联行号必填");
          return false;
        }
      }
      if (this.settleTableData.length > 0) {
        this.settleTableData = [data];
      } else {
        this.settleTableData.push(data);
      }
      this.overAccountShow = false;
    },

    showModal(type) {
      this.isShow = true;
      this.modalType = type;
      if (this.modalType === 3) {
        this.formData = Object.assign({}, this.detail);
      }
    },

    handleClickSync() {
      let data = {
        syncType: this.statusSync,
      };
      const thirdAccountId = this.accountTableData[0].thirdAccountId;
      getThirdaccounts(this.$route.query.id, thirdAccountId, data).then(
        (res) => {
          if (res && res.resultStatus) {
            this.$Message.success("同步请求成功");
            this.statusShow = false;
            this.getDetail();
          }
        }
      );
    },
    viewProtocol() {
      const thirdAccountId = this.accountTableData[0].thirdAccountId;
      setThirdaccountsSignC(this.$route.query.id, thirdAccountId).then(
        (res) => {
          if (res && res.resultStatus) {
            this.signHtml = res.resultData;
            this.isShow = false;
          }
        }
      );
    },
    copyLink() {
      const thirdAccountId = this.accountTableData[0].thirdAccountId;
      setThirdaccountsSignUrl(this.$route.query.id, thirdAccountId).then(
        (res) => {
          if (res && res.resultStatus) {
            this.copyLinkTitle = res.resultData.sign_url;
            let inputs = document.getElementById("copyLinkInput");
            inputs.value = this.copyLinkTitle;
            inputs.select();
            let actions = document.execCommand("Copy"); //指定复制命令(返回的是一个boolean类型)
            if (actions) {
              this.$Message.success("复制成功");
            } else {
              this.$Message.error("复制失败");
            }
          }
        }
      );
    },
    handleClickSign() {
      const thirdAccountId = this.accountTableData[0].thirdAccountId;
      setThirdaccountsSign(this.$route.query.id, thirdAccountId).then((res) => {
        if (res && res.resultStatus) {
          this.$Message.success("签约请求成功");
        }
      });
    },

    // 图片上传
    beforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = ["jpg", "jpeg", "png", "bpm"].includes(
        testmsg.toLocaleLowerCase()
      );
      if (!extension) {
        this.$Message.error("上传图片支持jpg/jpeg/png/bpm格式");
      }
      if (!extension) {
        return false;
      }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 1024).then((res) => {
          const fileOfBlob = new File(
            [res],
            `${new Date().getTime()}.${testmsg}`
          );
          resolve(fileOfBlob);
        });
      });
    },
    uploadImgs() {
      this.uploadImgsFlag = true;
      const data = {
        mchType: this.formData.mchType,
        idcardNormalPicture: this.formData.idcardNormalPicture,
        idcardReversePicture: this.formData.idcardReversePicture,
        licencePicture: this.formData.licencePicture,
        accountOpeningLicense: this.formData.accountOpeningLicense,
        otherPicture1: this.formData.otherPicture1,
        otherPicture2: this.formData.otherPicture2,
        operateType: this.approveImg === "NOT_UPLOAD" ? "UPLOAD" : "UPDATE",
      };
      console.log("最终提交数据", this.formData, data);
      setImage(this.$route.query.id, data).then((res) => {
        this.uploadImgsFlag = false;
        if (res && res.resultStatus) {
          this.isShow = false;
          this.$Message.success("图片上传成功");
          this.getDetail();
        }
      });
    },
    // 商户证件上传
    licencePictureSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.licencePicture = res.resultData.fileLink;
      }
    },
    // 身份证国徽照片（法人或联系人）上传
    idcardNormalPictureSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.idcardNormalPicture = res.resultData.fileLink;
      }
    },
    // 身份证人像照片（法人或联系人）上传
    idcardReversePictureSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.idcardReversePicture = res.resultData.fileLink;
      }
    },
    // 开户许可证上传
    accountOpeningLicenseSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.accountOpeningLicense = res.resultData.fileLink;
      }
    },
    // 其他类型图片1上传
    otherPicture1Success(res) {
      if (res && res.resultStatus) {
        this.formData.otherPicture1 = res.resultData.fileLink;
      }
    },
    // 其他类型图片2上传
    otherPicture2Success(res) {
      if (res && res.resultStatus) {
        this.formData.otherPicture2 = res.resultData.fileLink;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab-label {
  width: 150px;
  height: 38px;
  line-height: 38px;
  text-align: right;
  margin-right: 10px;
  i {
    color: var(--themeColor);
    margin-right: 2px;
  }
}
.tab3-form {
  .tab-label {
    width: 120px;
  }
}
.container {
  overflow: hidden;
  background: #fff;

  .status {
    display: flex;
    padding: 10px 20px;
    background-color: rgba(249, 249, 249, 1);
    margin-bottom: 30px;
    .label {
      width: 120px;
      font-weight: 500;
      color: var(--label);
    }
  }
  .title {
    color: var(--label);
    padding-left: 0;
  }
  .btn {
    display: inline-block;
    margin-right: 10px;
  }
  .edit-btn {
    width: 90px;
  }
  .add-btn {
    display: block;
    margin-top: 10px;
  }
  .lianjie {
    padding: 0 5px;
    color: var(--togoBtn);
    cursor: pointer;
    background: transparent;
    border: none;
    position: relative;
    font-size: 14px;
  }
  .tab1 {
    &-input {
      width: 100%;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(239, 239, 239, 1);
      border-radius: 4px;
      padding: 20px 30px 0px;
      .form {
        // overflow: hidden;
        height: 480px;
      }
      .form-item {
        float: left;
        width: 50%;
        display: flex;
        align-items: center;
        height: 40px;
        margin-bottom: 20px;
        .inputs {
          width: calc(100%);
          height: 36px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
          margin-left: 0;
        }
        .split {
          height: 40px;
          line-height: 40px;
          margin: 0 10px;
        }
        .date-picker {
          flex: 1;
        }
      }
      .form-address {
        display: flex;
        .tab-label {
          // width: 125px;
        }
        .form-date-picker {
          display: flex;
          width: calc(100%);
        }
      }
      .input-box > li > span {
        margin-right: 10px;
        width: 150px;
        display: block;
        text-align: right;
      }
    }
    &-footer {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      margin-bottom: 18px;
      button {
        width: 120px;
      }
      button:last-child {
        margin-left: 10px;
      }
    }
    &-upload {
      overflow: hidden;
      width: 100%;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(239, 239, 239, 1);
      border-radius: 4px;
      padding: 20px 30px;
      margin-top: 20px;
      .upload-form-item {
        float: left;
        width: 140px;
        margin-right: 30px;
        span {
          white-space: nowrap;
        }
      }
      .upload-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 140px;
        position: relative;
        img {
          width: 100%;
        }
        .icon-delete {
          position: absolute;
          right: 0;
          top: 4px;
        }
      }
    }
  }
  .attach-table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #eee;
    font-weight: 400;
    color: var(--label);
    th {
      font-weight: 400;
      padding: 30px 0;
      border: 1px solid #eee;
    }
    td {
      padding: 30px 0;
      border: 1px solid #eee;
      text-align: center;
    }
    .type {
      display: block;
      padding: 30px 0;
    }
    .content {
      width: 60%;
    }
  }
}
.sync-modal {
  width: 60%;
  margin: 0 auto;
  &-title {
    margin-bottom: 10px;
  }
}
.copy-btn {
  width: 90px;
  height: 30px;
  color: var(--title);
  font-size: 12px;
  text-align: center;
  margin: auto;
  line-height: 32px;
  border: 1px solid #dce0e6;
  line-height: 30px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}
.confirm-btn,
.cancel-btn {
  width: 60px;
  height: 32px;
}
.cover-view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
}
.webview {
  width: 50vw;
  height: 92%;
  padding: 50px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  overflow: auto;
  border-radius: 10px;
}
.overAccount_ul {
}
.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
}
.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}
.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.upload_ph p {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload_ph p span i {
  color: var(--themeColor);
  padding-right: 3px;
}

.upload_ph p span {
  font-size: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.upload_ph {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: wrap;
}
.uploader1 {
  width: 120px;
  margin: 5px;
}

.uploader1 h4 {
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  color: #000000;
}

.uploader1 h4 i {
  color: var(--themeColor);
  padding-right: 3px;
  font-family: "Microsoft YaHei";
}
.avatar-uploader {
  width: 120px;
  height: 120px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgb(241, 242, 244);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
}

.approveImg {
  width: 120px;
  height: 120px;
  display: block;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
}

/deep/ .ivu-table:before {
  display: none;
}
/deep/ .ivu-upload-drag {
  border: none;
  border-radius: 0;
}
</style>
<style>
.ivu-tooltip-inner {
  max-width: none;
}
.ivu-form-item-label {
  width: 150px !important;
}
.ivu-form-item-content {
  display: flex;
  flex: 1;
  margin-left: 0 !important;
}
.tab3-form {
  padding: 0 20px;
}
.tab3 .ivu-form-item-label {
  width: 100px !important;
}
</style>
