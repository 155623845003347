<template>
  <div>
    <div class="list clear">
      <ul class="listBox">
        <li v-for="(item, idx) in getList" :key="idx">
          <span>{{ item.title }}</span>
          <i v-if="item.key === 'allocMchType'">
            {{ detail.allocMchType | filterType(allocMchType) }}
          </i>
          <i v-else-if="item.key === 'mchType'">
            {{ detail.mchType | filterType(mchType) }}
          </i>
          <i v-else-if="item.key === 'allocMchRole'">
            {{ allocMchRoles[detail.allocMchRole] }}
          </i>
          <i v-else-if="item.key === 'licenceStartDate'">
            {{ detail.licenceStartDate }}
            <span v-if="detail.licenceStartDate && detail.licenceEndDate">至</span>
            {{ detail.licenceEndDate == "forever" ? "长期" : detail.licenceEndDate }}
          </i>
          <i v-else-if="item.key === 'legalPersonCemStartDate'">
            {{ detail.legalPersonCemStartDate }}
            <span v-if="detail.legalPersonCemStartDate && detail.legalPersonCemEndDate">至</span>
            {{ detail.legalPersonCemEndDate == "forever" ? "长期" : detail.legalPersonCemEndDate }}
          </i>
          <i v-else-if="item.showImg" class="ellipsis">
            <a v-if="detail[item.key]" @click="showImgModal(detail[item.key])">点击预览</a>
          </i>
          <i v-else style="display: block">
            <Tooltip
              v-if="item.tooltip && detail[item.key]"
              effect="light"
              style="height: 20px"
              :content="detail[item.key]"
              placement="top-start">
              <span class="ellipsis" style="display: block; width: 300px">{{ detail[item.key] }}</span>
            </Tooltip>
            <span v-else>{{ detail[item.key] }}</span>
          </i>
        </li>
      </ul>
    </div>
    <!-- 查看图片 -->
    <BigImage :show-image.sync="showBigImg" :src="bigImgSrc" />
  </div>
</template>

<script>
import BigImage from '@/components/BigImage'
import address from "@/utils/address.json"
export default {
  components: { BigImage },
  filters: {
    filterType (val, options) {
      if (!val) { return '' }
      let result = options.filter(item => item.value === val)
      return result.length > 0 && result[0].title
    },
    filterProvince (val) {
      const obj = address.find((v) => v.provinceCode == val) || {}
      return obj.province
    },
    filterCity (val, provinceCode) {
      if (!provinceCode) { return }
      const province = address.find((v) => v.provinceCode == provinceCode).citys
      return province.find((v) => v.cityCode == val).city
    },
    filterDistrict (val, provinceCode, cityCode) {
      if (!provinceCode || !cityCode) { return }
      const province = address.find((v) => v.provinceCode == provinceCode).citys
      const city = province.find((v) => v.cityCode == cityCode).countys
      return city.find((v) => v.countyCode == val).county
    }
  },
  computed: {
    getList () {
      if (this.detail.allocMchType === 'PLATALLOC') {
        return this.detailColumns.filter(item => item.key !== 'allocMchRole')
      }
      return this.detailColumns
    }
  },
  data () {
    return {
      detailColumns: [
        { title: '分账方全称', key: 'allocMchName', tooltip: true },
        { title: '分账方属性', key: 'allocMchType' },
        { title: '分账方编号', key: 'allocMchId', tooltip: true },
        { title: '分账方简称', key: 'mchShortName' },
        // { title: '所属商户', key: 'mchName', tooltip: true },
        { title: '分账方角色', key: 'allocMchRole' },
        { title: '分账方类型', key: 'mchType' },
        { title: '联系人', key: 'contactName' },
        { title: '联系人手机号', key: 'contactTel', tooltip: true },
        { title: '法人姓名', key: 'legalPersonName' },
        { title: '法人身份证号码', key: 'legalPersonNo', tooltip: true },
        { title: '法人手机号码', key: 'legalPersonPhone' },
        { title: '法人证件有效期', key: 'legalPersonCemStartDate' },
        { title: '统一社会信用代码', key: 'licenceNo', tooltip: true },
        { title: '营业执照有效期', key: 'licenceStartDate' },
        { title: '经营范围', key: 'businessScope', tooltip: true },
        { title: '营业地址', key: 'businessAddress', tooltip: true },
        { title: '营业执照', key: 'licencePicture', showImg: true },
        { title: '法人身份证国徽照片', key: 'idcardNormalPicture', showImg: true },
        { title: '法人身份证人像照片', key: 'idcardReversePicture', showImg: true },
        { title: '开户许可证', key: 'accountOpeningLicense', showImg: true },
        { title: '其他类型图片1', key: 'otherPicture1', showImg: true },
        { title: '其他类型图片2', key: 'otherPicture2', showImg: true },
        { title: '创建时间', key: 'createTime' }
      ],
      mchType: [
        { title: "企业", value: "ENTERPRISE" },
        { title: "个体工商户", value: "INDIVIDUAL_BUSINESS" },
        { title: '个人', value: 'PERSONAL' }
      ],
      allocMchType: [
        { title: '平台分账方', value: 'PLATALLOC' },
        { title: '服务商分账方', value: 'AGENTALLOC' }
      ],
      allocMchRoles: {
        SUPPLIER: '供应商',
        DISTRIBUTOR: '分销商',
        MCN: 'MCN机构',
        SELLER: '销售员',
        ANCHOR: '主播',
        OTHER: '其他',
      },
      // 查看图片大图
      showBigImg: false,
      bigImgSrc: null
    }
  },
  props: {
    detail: {
      type: Object,
      default: {},
    },
  },
  methods: {
    // 查看图片
    showImgModal (cover) {
      this.showBigImg = true
      this.bigImgSrc = cover
    },
  },
};
</script>

<style lang="less" scoped>
</style>